<template>
  <div class="government-section container-fluid px-0">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 d-flex justify-content-center order-2 order-lg-1 mt-4 mt-lg-0">
          <div class="contents text-center text-lg-start align-self-center">
            <p class="mb-0 header mb-5 text-center text-lg-start">
              Trusted by Governments Across Australia
            </p>
            <p class="mb-0 description text-center text-lg-start">
              Career Tools & Year13 works with Governments across Australia, contributing to
              Government and industry reviews into careers education, social services and economic
              development.
            </p>
            <Button
              size="md"
              to="/our-story"
              variant="filled"
              userType="teacher"
              class="mt-4 text-center text-lg-start"
            >
              View Our Work
            </Button>
          </div>
        </div>
        <div class="col-12 col-lg-6 order-1 order-lg-2">
          <img src="/icons/home/government.svg" class="img-fluid" alt="gov" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Button from '@/components/Button';

export default defineComponent({
  name: 'root-government-section',
  components: {
    Button,
  },
});
</script>

<style lang="scss" scoped>
.government-section {
  background-color: #eceef1;
  padding-top: 125px;
  padding-bottom: 125px;

  .contents {
    @include m-large {
      max-width: 315px;
    }

    .header {
      line-height: 32px;
      font-size: 28px;
      font-weight: 700;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
    }
  }

  img {
    background: url('/icons/home/blue-blob.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>

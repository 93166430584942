<template>
  <div class="container-fluid px-0 banner-a">
    <div class="bg-blue-splat-right"></div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-4 mb-5 order-1">
          <TopNavigation componentName="SliderA" />
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center mt-4 mt-lg-0 order-3 order-lg-2">
          <div class="content align-self-center">
            <p class="sub-header">Career Tools Platform</p>
            <p class="my-4 header">Career Tools is Designed To Make Career Advisors Lives Easier</p>
            <p class="mb-0">
              Brimming with thoughtfully-designed career resources and activities for students, our
              state-of-the-art platform means teachers and advisors can provide engaging and
              impactful career advice.
            </p>
            <Button size="md" to="/enquiry" variant="filled" userType="teacher" class="mt-4">
              Request A Demo
            </Button>
          </div>
        </div>
        <div class="col-12 col-lg-6 order-2 order-lg-3">
          <img src="/icons/home/slider-a-screens.svg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Button from '@/components/Button';
import TopNavigation from '@/components/root/home/sliders/TopNavigation';

export default defineComponent({
  name: 'root-slider-a',
  components: { Button, TopNavigation },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.banner-a {
  overflow: hidden;
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: #dfeaf1;

  @include m-large {
    min-height: 671px;
  }

  .bg-blue-splat-right {
    background-image: url('/backgrounds/blue-splat-1.svg');
    background-repeat: no-repeat;
    background-position: 0% 80%;
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    @include m-medium {
      background-position: 0% 50%;
    }
  }

  img {
    position: relative;
  }

  .content {
    position: relative;
    max-width: 455px;

    p {
      font-size: 22px;
      color: $business;
      line-height: 31px;

      &.sub-header {
        color: #2462d2;
        font-size: 16px;
        font-weight: 700;
      }

      &.header {
        font-size: 38px;
        font-weight: 700;
        line-height: 50px;
      }
    }
  }
}
</style>

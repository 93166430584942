<template>
  <div class="d-none d-lg-flex flex-lg-row justify-content-between">
    <button
      type="button"
      data-bs-target="#slider-section"
      data-bs-slide-to="0"
      class="text-end"
      aria-current="true"
      aria-label="Career Advisors"
      :data-active="componentName === 'SliderA'"
    >
      Career Advisors
    </button>
    <button
      type="button"
      data-bs-target="#slider-section"
      data-bs-slide-to="1"
      aria-label="Career Journeys"
      :data-active="componentName === 'SliderB'"
    >
      Career Journeys
    </button>
    <button
      type="button"
      data-bs-target="#slider-section"
      data-bs-slide-to="2"
      aria-label="Virtual Job Experience"
      :data-active="componentName === 'SliderC'"
    >
      Virtual Job Experience
    </button>
    <button
      type="button"
      data-bs-target="#slider-section"
      data-bs-slide-to="3"
      aria-label="Schools"
      :data-active="componentName === 'SliderD'"
    >
      Schools
    </button>
    <button
      type="button"
      data-bs-target="#slider-section"
      data-bs-slide-to="4"
      aria-label="Achieveworks"
      :data-active="componentName === 'SliderE'"
    >
      Achieveworks
    </button>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'slider-top-navigation',
  props: {
    componentName: {
      type: String,
      required: true,
    },
  },
  setup() {
    const navItems = ref([
      'Career Advisors',
      'Career Journeys',
      'Virtual Job Experience',
      'Schools',
      'Achieveworks',
    ]);
    return { navItems };
  },
});
</script>

<style lang="scss" scoped>
button {
  position: relative;
  font-size: 16px;
  color: $business;
  font-weight: 700;
  padding-bottom: 5px;
  width: fit-content;
  border: none;
  background: transparent;
}

[data-active='true'] {
  border-bottom: 3px solid #2462d2;

  &:nth-child(2) {
    border-bottom: 3px solid $cerise;
  }
}
</style>

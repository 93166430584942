<template>
  <div class="container-fluid px-0 banner-a">
    <div class="bg-blue-splat-right"></div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-4 mb-5 order-1">
          <TopNavigation componentName="SliderD" />
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center mt-4 mt-lg-0 order-3 order-lg-2">
          <div class="content align-self-center">
            <p class="sub-header">Career Tools Platform</p>
            <p class="my-4 header">Your Own Branded Careers Website</p>
            <p>
              Share news and resources with your school with a customised careers website, fully
              maintained and updated by our team.
            </p>
            <p>
              Not only will this save you time and enhance communication, but it also raises the
              profile of careers and what you do in your school.
            </p>
            <Button
              size="md"
              to="/your-custom-website"
              variant="filled"
              userType="teacher"
              class="mt-4"
            >
              Your Careers Website
            </Button>
          </div>
        </div>
        <div class="col-12 col-lg-6 order-2 order-lg-3">
          <img src="/icons/home/slider-d-screens.svg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Button from '@/components/Button';
import TopNavigation from '@/components/root/home/sliders/TopNavigation';

export default defineComponent({
  name: 'root-slider-d',
  components: { Button, TopNavigation },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.banner-a {
  overflow: hidden;
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: #dfeaf1;

  @include m-large {
    min-height: 671px;
  }

  .bg-blue-splat-right {
    background-image: url('/icons/home/splat-blue-slider.svg');
    background-repeat: no-repeat;
    background-position: 0% 80%;
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    @include m-medium {
      background-position: right;
    }
  }

  img {
    position: relative;
  }

  .content {
    position: relative;
    max-width: 455px;

    p {
      font-size: 22px;
      color: $business;
      line-height: 31px;

      &.sub-header {
        color: #2462d2;
        font-size: 16px;
        font-weight: 700;
      }

      &.header {
        font-size: 38px;
        font-weight: 700;
        line-height: 50px;
      }
    }
  }

  .nav-text {
    position: relative;
    font-size: 16px;
    color: $business;
    font-weight: 700;
    padding-bottom: 5px;
    width: fit-content;

    &:nth-child(4) {
      border-bottom: 3px solid #2462d2;
    }
  }
}
</style>

<template>
  <div class="container empowering-section">
    <div class="row">
      <div class="col-12 col-lg-6 order-2 order-lg-1 mt-5">
        <img src="/icons/home/menu-display.svg" class="img-fluid" alt="menu-display" />
      </div>
      <div class="col-12 col-lg-6 d-flex justify-content-center order-1 order-lg-2">
        <div class="content align-self-center">
          <h3>Unparalleled student engagement</h3>
          <p class="my-4">
            Our Career Plan and E-portfolio provides tangible outcomes for students.
          </p>
          <div v-for="(text, i) in texts" :key="`text-${i}`" class="d-flex description">
            <IconDefault path="check-plain" :width="18" class="me-3" />
            <p class="text align-self-center mb-0">{{ text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';

export default defineComponent({
  name: 'root-empowering-section',
  components: {
    IconDefault,
  },
  setup() {
    const texts = ref([
      '170,000 E-portfolios completed',
      '65,945 Appointments Booked with Careers Advisors',
      '339,359 Careers Explored',
      '24,183 Work Experiences Completed',
    ]);

    return { texts };
  },
});
</script>

<style lang="scss" scoped>
.empowering-section {
  padding-top: 46px;
  padding-bottom: 46px;

  .content {
    max-width: 420px;

    .description {
      margin-bottom: 14px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .text {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
</style>

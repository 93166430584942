<template>
  <div class="container-fluid px-0 banner-b">
    <div class="bg-blue-red-right"></div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 d-flex justify-content-center mt-4 mt-lg-0 order-2 order-lg-1">
          <div class="content align-self-center">
            <div
              class="mb-5"
              v-tooltip="{
                content: 'Careers Advisors - Out of the Shadows Report (YouthSense 2021-2023)',
                theme: 'white-tooltip',
              }"
            >
              <p class="header">Australia’s Leading Career Development Platform for Students *</p>
            </div>
            <p>The all-in-one career education resource you’ve been waiting for.</p>
            <Button
              size="md"
              @click="goto('slider-section')"
              variant="filled"
              userType="student"
              class="learn-more-button"
            >
              Learn More
            </Button>
          </div>
        </div>
        <div class="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center">
          <div class="align-self-center">
            <img src="/icons/home/banner-b.svg" class="img-fluid d-block d-lg-none" alt="" />
            <LottieAnimation
              class="d-none d-lg-block"
              :width="700"
              :height="545"
              :animationData="animation"
            />
          </div>
        </div>
        <div class="col-12 col-lg-7 mt-4 mt-lg-0 order-3">
          <p class="small-cap-text ps-lg-8">ENHANCING PRODUCTIVITY FOR</p>
          <div class="d-flex flex-column flex-lg-row justify-content-between ps-lg-8">
            <div v-for="(text, i) in texts" :key="`text-${i}`" class="text mb-0">
              <IconDefault path="/home/check-img" :width="18" class="me-2" />
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import useScroll from '@/hooks/scrollTo';
import Button from '@/components/Button';
import * as animationData from '@/static/animations/red.json';
import LottieAnimation from '@/components/LottieAnimation';

export default defineComponent({
  name: 'root-banner-b',
  components: {
    IconDefault,
    Button,
    LottieAnimation,
  },
  setup() {
    const { goto } = useScroll();

    const texts = ref(['1,200+ Schools', '8,000+ Teachers', '400,000 Students']);
    const animation = computed(() => animationData);

    return { texts, goto, animation };
  },
});
</script>

<style lang="scss" scoped>
.banner-b {
  overflow: hidden;
  position: relative;
  padding-top: 96px;
  padding-bottom: 48px;
  background-color: #2f3957;

  @include m-large {
    height: 100vh;
  }

  .bg-blue-red-right {
    background-image: url('/backgrounds/splat-red-large.svg');
    background-repeat: no-repeat;
    background-position: 0% 80%;
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    @include m-medium {
      background-position: 0% 50%;
    }
  }

  img {
    position: relative;
  }

  .content {
    position: relative;
    max-width: 455px;

    p {
      &.header {
        font-size: 38px;
        font-weight: 700;
        line-height: 50px;
      }

      font-size: 22px;
      color: #fff;
      line-height: 31px;
    }
  }

  .small-cap-text {
    font-size: 14px;
    color: #fff;
  }

  .text {
    position: relative;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;
    }

    @include m-large {
      margin-top: 0;
    }
  }
}

.learn-more-button {
  border: 1px solid #c108b8;
}
</style>

<template>
  <div class="container-fluid px-0 testimonials-section d-flex justify-content-center">
    <div class="container align-self-center">
      <div class="row">
        <div class="col-12">
          <div
            id="carouselExampleIndicators"
            class="carousel slide mx-auto"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div>
                  <h3 class="mb-4">
                    Careers Tools has helped shape careers and make it more prominent in the school
                    as a student driven initiative. I LOVE YOUR PRODUCT.
                  </h3>
                  <p>Carolyn Carter, St Francis Xavier College, VIC</p>
                </div>
              </div>
              <div class="carousel-item">
                <div>
                  <h3 class="mb-4">
                    The power of the website and career journeys is perfect for our school. The
                    price we pay is fantastic. We definitely get value for money.
                  </h3>
                  <p>Nathan Honeyman, Albury High School, NSW</p>
                </div>
              </div>
              <div class="carousel-item">
                <div>
                  <h3 class="mb-4">
                    I love that I can impersonate students, view each student's progress and edit
                    their profile details in bulk using a simple csv file. It's also nice to see
                    that students will log in and use it with no prompting. It is very user
                    friendly.
                  </h3>
                  <p>Trish Brown, St Clare's College, ACT</p>
                </div>
              </div>
              <div class="carousel-item">
                <div>
                  <h3 class="mb-4">
                    I'm a big fan and try to get the most out of the entire platform. I save at
                    least half a day per week just on communications by utilising Career Tools
                    features.
                  </h3>
                  <p>Janine Guidera, Lynwood Senior High School, WA</p>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'root-testimonials-section',
});
</script>

<style lang="scss" scoped>
.testimonials-section {
  background-color: $business;
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 500px;

  .carousel {
    color: #fff;
    //max-width: 625px;

    .carousel-item > div {
      h3,
      p {
        color: #fff;
      }

      min-height: 200px;
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 70px;
      padding-bottom: 80px;
      background: url('/images/brand/quotes-grey.svg') no-repeat;
      @include m-large {
        margin-left: 40px;
        margin-right: 40px;
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 45px;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: auto;
      top: auto;
      bottom: 5px;
      @include m-large {
        top: 0;
        bottom: 0;
      }
    }

    .carousel-indicators [data-bs-target] {
      width: 10px;
      border-radius: 10px;
      height: 10px;
      border: 0;
    }
  }
}
</style>

<template>
  <div id="homeBannerIndicators" class="carousel slide" data-pause="hover" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#homeBannerIndicators"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#homeBannerIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <BannerA />
      </div>
      <div class="carousel-item">
        <BannerB />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import BannerA from './banners/BannerA';
import BannerB from './banners/BannerB';

export default defineComponent({
  name: 'root-banner',
  components: {
    BannerA,
    BannerB,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>

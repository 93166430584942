<template>
  <div class="container-fluid px-0 why-section">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-4">
          <h3>Why Choose Career Tools?</h3>
        </div>
        <div
          class="col-12 col-lg-4 text-center info"
          v-for="(section, i) in sections"
          :key="`section-${i}`"
        >
          <IconDefault :path="`home/${section.icon}`" :width="100" />
          <p class="name my-4">{{ section.name }}</p>
          <p class="description mx-auto mb-0">{{ section.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';

export default defineComponent({
  name: 'root-why-careertools-section',
  components: {
    IconDefault,
  },

  setup() {
    const sections = ref([
      {
        icon: 'leaders',
        name: 'Education Expertise',
        description:
          'Feel confident knowing activities and resources are created by career practitioners with 25+ years of experience.',
      },
      {
        icon: 'compliant',
        name: 'Security & Data',
        description:
          'Keep student data safe with security infrastructure and privacy policies of the highest Australian standard.',
      },
      {
        icon: 'rating',
        name: '98% Satisfaction rate',
        description: 'Join a platform that’s been loved by 1200+ schools for over 10 years.',
      },
    ]);
    return { sections };
  },
});
</script>

<style lang="scss" scoped>
.why-section {
  padding-top: 125px;
  padding-bottom: 125px;
  background-color: #f3f4f6;

  .info {
    margin-top: 50px;

    @include m-large {
      margin-top: 0;
    }
  }

  .name {
    font-size: 18px;
    font-weight: 700;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    max-width: 228px;
  }
}
</style>

<template>
  <div class="lottie-animation" :style="`width: ${width}px; height: ${height}px;`">
    <client-only>
      <lottie
        v-on:animCreated="handleAnimation"
        :width="width"
        :height="height"
        :options="lottieOptions"
      />
    </client-only>
  </div>
</template>

<script>
import { defineComponent, ref, onUnmounted } from '@nuxtjs/composition-api';
import lottie from 'vue-lottie/src/lottie.vue';

export default defineComponent({
  name: 'lottie-animation',
  components: {
    lottie,
  },
  props: {
    animationData: {
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const anim = ref(null);

    const lottieOptions = ref({ animationData: props.animationData.default });

    const handleAnimation = (val) => {
      anim.value = val;
    };

    onUnmounted(() => {
      anim.value.destroy();
    });

    return { anim, handleAnimation, lottieOptions };
  },
});
</script>

<template>
  <div class="container features-section">
    <div class="row">
      <div class="col-12 text-center mb-4">
        <h3>Features Include:</h3>
      </div>
      <!-- card one -->
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="content">
            <div class="d-flex justify-content-between">
              <p class="mb-0 align-self-center">For Teachers</p>
              <IconDefault path="c-logo-teacher-old" :width="40" />
            </div>
            <hr class="my-4" />
            <ul>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Customisable and branded school careers website
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Unlimited student accounts
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Unlimited teacher/staff accounts
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Record student interviews and interactions
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Email and phone support
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Customisable Destination Survey
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Lesson outlines mapped to Australian Blueprint of Career Development, Australian
                Curriculum and state curricula
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Full suite of teacher resources including newsletters, events calendar and career
                guides to save you time
              </li>
              <li>And much more!</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- card two -->
      <div class="col-12 col-lg-6 mt-5 mt-lg-0">
        <div class="card">
          <div class="content">
            <div class="d-flex justify-content-between">
              <p class="mb-0 align-self-center">For Students</p>
              <IconDefault path="c-logo-student-old" :width="40" />
            </div>
            <hr class="my-4" />
            <ul>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Career Planner (customised per state requirements)
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Career and personality profiling
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                E-Portfolio Creator
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Career Investigator tool
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Resume and Cover Letter builders
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                55+ Career Lessons
              </li>
              <li>
                <IconDefault path="check-plain" :width="18" />
                Search Career Plan data and generate custom reports
              </li>
              <li class="mt-lg-7">And much more!</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-6">
        <Button size="md" to="/enquiry" variant="filled" userType="teacher">
          Request A Demo
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import Button from '@/components/Button';

export default defineComponent({
  name: 'root-features-section',
  components: {
    IconDefault,
    Button,
  },
});
</script>

<style lang="scss" scoped>
.features-section {
  padding-top: 125px;
  padding-bottom: 125px;

  .card {
    border: 8px solid #eceef1;
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 100%;

    .content {
      background-color: #fff;
      padding: 32px;
      border-radius: 16px;

      p {
        font-size: 22px;
        font-weight: 700;
      }

      ul {
        list-style-type: none;

        li {
          display: flex;
          margin-bottom: 15px;
          font-size: 18px;
          font-weight: 700;

          img {
            margin-right: 10px;
          }

          &:last-of-type {
            margin-bottom: 0;
            font-size: 22px;
          }
        }
      }
    }
  }
}
</style>

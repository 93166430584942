<template>
  <div id="slider-section" class="carousel slide" data-pause="hover" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#slider-section"
        data-bs-slide-to="0"
        class="active text-end"
        aria-current="true"
        aria-label="Slide 1"
      />
      <button
        type="button"
        data-bs-target="#slider-section"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      />
      <button
        type="button"
        data-bs-target="#slider-section"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      />
      <button
        type="button"
        data-bs-target="#slider-section"
        data-bs-slide-to="3"
        aria-label="Slide 4"
      />
      <button
        type="button"
        data-bs-target="#slider-section"
        data-bs-slide-to="4"
        aria-label="Slide 5"
      />
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <SliderA />
      </div>
      <div class="carousel-item">
        <SliderB />
      </div>
      <div class="carousel-item">
        <SliderC />
      </div>
      <div class="carousel-item">
        <SliderD />
      </div>
      <div class="carousel-item">
        <SliderE />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import SliderA from './sliders/SliderA';
import SliderB from './sliders/SliderB';
import SliderC from './sliders/SliderC';
import SliderD from './sliders/SliderD';
import SliderE from './sliders/SliderE';

export default defineComponent({
  name: 'root-slider-section',

  components: {
    SliderA,
    SliderB,
    SliderC,
    SliderD,
    SliderE,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
[data-bs-target='#slider-section'] {
  background-color: $business;
}
</style>

export default function useScroll() {
  const goto = (id, offset = 0) => {
    const elem = document.getElementById(id);

    if (elem) {
      window.scroll({ top: elem.offsetTop - offset, left: 0, behavior: 'smooth' });
      elem.focus();
    }
  };

  return { goto };
}

<template>
  <div>
    <div class="wrapper">
      <HomeBanner />
      <FoundedSection />
      <SliderSection id="slider-section" />
      <EmpoweringSection />
      <WhyCareertoolsSection />
      <TestimonialsSection />
      <FeaturesSection />
      <CoverageSection />
      <GovernmentSection />
      <SubscriptionSection />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import FoundedSection from '@/components/root/home/FoundedSection';
import WhyCareertoolsSection from '@/components/root/home/WhyCareertoolsSection';
import GovernmentSection from '@/components/root/home/GovernmentSection';
import CoverageSection from '@/components/root/home/CoverageSection';
import FeaturesSection from '@/components/root/home/FeaturesSection';
import EmpoweringSection from '@/components/root/home/EmpoweringSection';
import TestimonialsSection from '@/components/root/home/TestimonialsSection';
import HomeBanner from '@/components/root/home/HomeBanner';
import SliderSection from '@/components/root/home/SliderSection';
import SubscriptionSection from '@/components/root/home/SubscriptionSection';

export default defineComponent({
  name: 'root-index',
  layout: 'root/default',
  auth: 'guest',
  components: {
    HomeBanner,
    FoundedSection,
    WhyCareertoolsSection,
    GovernmentSection,
    CoverageSection,
    FeaturesSection,
    EmpoweringSection,
    TestimonialsSection,
    SliderSection,
    SubscriptionSection,
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  overflow: hidden;
}
</style>

<template>
  <div class="coverage-section container-fluid px-0">
    <div class="row">
      <div class="col-12 col-lg-6 d-flex justify-content-center py-5 py-lg-0">
        <div class="content align-self-center text-center text-lg-start">
          <h3 class="mb-4">Career Tools Coverage</h3>
          <p>
            Career Tools is helping more career advisors, students and schools than any other
            platform in the country. Our coverage makes us the industry leaders in the career advice
            sector.
          </p>
          <Button size="md" to="/enquiry" variant="filled" userType="teacher" class="mt-4">
            Request A Demo
          </Button>
        </div>
      </div>
      <div class="col-12 col-lg-6 map">
        <img src="/icons/home/coverage-map.svg" class="img-fluid w-100" alt="coverage-map" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Button from '@/components/Button';

export default defineComponent({
  name: 'root-coverage-section',
  components: {
    Button,
  },
});
</script>

<style lang="scss" scoped>
.coverage-section {
  background-color: #fff;

  .map {
    background: linear-gradient(to right, #fff, #2462d2);
  }

  .content {
    max-width: 345px;

    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }
  }
}
</style>
